<template>
    <div class="root">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>{{ $route.name }}</span>
            </div>

            <!-- 搜索表单 -->
            <el-form :inline="true" :model="searchData">
                <el-form-item label="平台名">
                    <el-input v-model="searchData.name" size="small" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="平台分类">
                    <el-select v-model="searchData.sort" placeholder="请选择" size="small">
                        <el-option label="" value=""></el-option>
                        <el-option v-for="(item, i) in sortData" :key="'sort' + i" :label="item.name"
                                   :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="small" plain @click="search">查询</el-button>
                </el-form-item>
                <el-form-item style="float: right">
                    <el-button type="success" size="small" plain @click="popup(emptyData)">新增</el-button>
                </el-form-item>
            </el-form>

            <!-- 表格 -->
            <el-table :data="tableData" stripe style="width: 100%" v-loading="tableloading">
                <!-- 表格数据列 -->
                <el-table-column prop="id" label="ID" width="70"></el-table-column>
                <el-table-column prop="name" label="平台名"></el-table-column>
                <el-table-column label="图标">
                    <template slot-scope="item">
                        <i v-if="item.row.source_key" :class="'iconfont icon-' + item.row.source_key"
                           :style="'color:' + item.row.icon_color"></i>
                        <span v-else></span>
                    </template>
                </el-table-column>
                <el-table-column prop="sort_name" label="平台分类"></el-table-column>
                <el-table-column label="爬虫配置" width="600">
                    <template slot-scope="item">
                        <div class="craw-detail">
                            <p>
                                <el-tag size="small">{{ item.row.craw_method }}</el-tag>
                                <span style="margin-left: 5px">{{ item.row.craw_url }}</span>
                            </p>
                            <p>爬取间隔：{{ cronTime[item.row.craw_interval] }}</p>
                            <!-- <p>数据来源：{{item.row.craw_data}}</p> -->
                            <p>数据方法：{{ item.row.craw_type == 1 ? '选择器' : 'JSON' }}</p>
                            <p>循环方式：{{ item.row.craw_loop_type == 1 ? '选择器' : 'JSON' }}</p>
                            <!-- <p>请求头：{{item.row.craw_header}}</p>
                            <p>请求参数：{{item.row.craw_param}}</p>
                            <p>记录标题：{{item.row.craw_data_title}}</p>
                            <p>记录详情：{{item.row.craw_data_detail}}</p>
                            <p>记录链接：{{item.row.craw_data_link}}</p>
                            <p>记录额外：{{item.row.craw_data_extra}}</p> -->
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="160">
                    <template slot-scope="item">
                        <el-button type="primary" size="mini" @click="popup(item.row, item.$index)">编辑</el-button>
                        <el-button type="danger" size="mini" @click="deleteSource(item.$index)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 表格分页 -->
            <div class="pager">
                <el-pagination background layout="prev, pager, next" :page-size="20" :total="pageTotal"
                               :current-page="searchData.page" @current-change="changePage"></el-pagination>
            </div>

            <!-- 编辑/新增弹窗 -->
            <el-dialog :title="(editData.id == undefined ? '新增' : '编辑') + '平台'" :visible.sync="editShow"
                       :close-on-click-modal="false" :close-on-press-escape="false" @closed="closeEdit">

                <el-form :model="editData" :rules="editDataRule" ref="editForm" label-width="85px" label-position="right"
                         v-loading="editLoading">

                    <el-form-item label="平台名称" prop="name">
                        <el-input v-model="editData.name" auto-complete="off" size="medium"></el-input>
                    </el-form-item>

                    <el-form-item label="平台分类" style="width: 300px; display: inline-block;" prop="sort">
                        <el-select v-model="editData.sort" placeholder="请选择" size="medium">
                            <el-option v-for="(item, i) in sortData" :key="'sort' + i" :label="item.name"
                                       :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>

                    <!-- <el-form-item label="私密" style="width: 300px; display: inline-block;">
                        <el-select v-model="editData.private" placeholder="请选择" size="medium">
                            <el-option label="否" :value="0"></el-option>
                            <el-option label="是" :value="1"></el-option>
                        </el-select>
                    </el-form-item> -->

                    <el-form-item label="图标" style="width: 440px; display: inline-block;vertical-align: top;"
                                  prop="source_key">
                        <el-col :span="10">
                            <el-input v-model="editData.source_key" auto-complete="off" placeholder="请输入icon"
                                      size="medium"></el-input>
                        </el-col>
                        <el-col :span="4">
                            <el-color-picker v-model="editData.icon_color" style="margin-left: 10px"></el-color-picker>
                        </el-col>
                        <el-col :span="2">
                            <i v-if="editData.source_key" :class="'iconfont icon-' + editData.source_key"
                               :style="'color:' + editData.icon_color"></i>
                        </el-col>
                    </el-form-item>

                    <el-divider content-position="left">爬虫配置</el-divider>

                    <el-form-item label="请求链接" prop="craw_url">
                        <el-input placeholder="请输入内容" v-model="editData.craw_url" size="medium">
                            <el-select v-model="editData.craw_method" slot="prepend" placeholder="请选择" style="width: 100px"
                                       size="medium">
                                <el-option label="GET" value="GET"></el-option>
                                <el-option label="POST" value="POST"></el-option>
                                <el-option label="PUT" value="PUT"></el-option>
                            </el-select>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="请求间隔" prop="craw_interval">
                        <el-select v-model="editData.craw_interval" placeholder="请选择" size="medium">
                            <el-option v-for="(item, i) in cronTime" :key="'time' + i" :label="item" :value="i"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="请求头">
                        <el-input v-model="editData.craw_header" auto-complete="off" size="medium"
                                  placeholder='{"xx":"xx"}'></el-input>
                    </el-form-item>

                    <el-form-item label="请求参数">
                        <el-input v-model="editData.craw_param" auto-complete="off" size="medium"
                                  placeholder='{"xx":"xx"}'></el-input>
                    </el-form-item>

                    <el-form-item label="数据获取" style="width: 300px; display: inline-block;" prop="craw_type">
                        <el-select v-model="editData.craw_type" placeholder="请选择" size="medium" @change="dataTypeChange">
                            <el-option label="DOM" value="1"></el-option>
                            <el-option label="JSON" value="2"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="数据来源" prop="craw_data">
                        <el-input v-model="editData.craw_data" auto-complete="off" size="medium"></el-input>
                    </el-form-item>

                    <el-divider content-position="left">入库配置</el-divider>

                    <el-form-item label="循环方式" style="width: 300px; display: inline-block;" prop="craw_loop_type">
                        <el-select v-model="editData.craw_loop_type" placeholder="请选择" size="medium"
                                   @change="loopTypeChange">
                            <el-option label="DOM" value="1"></el-option>
                            <el-option label="JSON" value="2"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="标题" prop="craw_data_title">
                        <el-input v-model="editData.craw_data_title" auto-complete="off" size="medium"></el-input>
                    </el-form-item>

                    <el-form-item label="链接" prop="craw_data_link">
                        <el-input v-model="editData.craw_data_link" auto-complete="off" size="medium"></el-input>
                    </el-form-item>

                    <el-form-item label="详情">
                        <el-input v-model="editData.craw_data_detail" auto-complete="off" size="medium"></el-input>
                    </el-form-item>

                    <el-form-item label="额外">
                        <el-input v-model="editData.craw_data_extra" auto-complete="off" size="medium"></el-input>
                    </el-form-item>

                    <el-form-item label="">
                        <el-button size="small" plain type="primary" :loading="crawLoading"
                                   @click="crawTest">{{ crawLoading ? '爬取中' : '爬取测试' }}</el-button>
                    </el-form-item>

                    <el-form-item label="数据结果" v-if="crawTestRes.message">
                        <el-input type="textarea" :rows="10" size="medium" :value="JSON.stringify(crawTestRes, null, 4)"
                                  readonly="">
                        </el-input>
                    </el-form-item>

                    <el-form-item label="原始数据" v-if="crawTestRes.message">
                        <el-input type="textarea" :rows="10" size="medium" :value="crawTestOrigin" readonly="">
                        </el-input>
                    </el-form-item>
                </el-form>

                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="editShow = false">取消</el-button>
                    <el-button size="small" type="primary" :loading="editLoading" @click="submit">保存</el-button>
                </div>
            </el-dialog>

        </el-card>
    </div>
</template>

<script>
let emptyData = {
    "name": '',
    "sort": 1,
    "source_key": '',
    "icon_color": '',
    "craw_interval": '',
    "craw_method": 'GET',
    "craw_url": '',
    "craw_header": '',
    "craw_param": '',
    "craw_type": '1',
    "craw_data": '$(".list-item")',
    "craw_loop_type": '1',
    "craw_data_title": 'that.find(".title").text()',
    "craw_data_detail": '',
    "craw_data_extra": '',
    "craw_data_link": 'that.find("a").attr("href")',

    // "private": 0
}
export default {
    data() {
        return {
            // 尝试爬取
            crawLoading: false,
            crawTestOrigin: '',
            crawTestRes: {},
            // 弹窗数据
            editLoading: false,
            editShow: false,
            editData: emptyData,
            emptyData: emptyData,
            editDataRule: {
                name: [{
                    required: true,
                    message: '请填写平台名字',
                    trigger: 'blur'
                }],
                sort: [{
                    required: true,
                    message: '请选择平台分类',
                    trigger: 'change'
                }],
                source_key: [{
                    required: true,
                    message: '请填写图标标识',
                    trigger: 'blur'
                }],
                craw_interval: [{
                    required: true,
                    message: '请填写请求间隔',
                    trigger: 'blur'
                }],
                craw_method: [{
                    required: true,
                    message: '请填写请求方法',
                    trigger: 'blur'
                }],
                craw_url: [{
                    required: true,
                    message: '请填写请求链接',
                    trigger: 'blur'
                }],
                craw_type: [{
                    required: true,
                    message: '请选择数据方法',
                    trigger: 'change'
                }],
                craw_loop_type: [{
                    required: true,
                    message: '请选择循环方式',
                    trigger: 'change'
                }],
                craw_data: [{
                    required: true,
                    message: '请填写数据来源',
                    trigger: 'blur'
                }],
                craw_data_title: [{
                    required: true,
                    message: '请填写入库标题',
                    trigger: 'blur'
                }],
                craw_data_link: [{
                    required: true,
                    message: '请填写入库链接',
                    trigger: 'blur'
                }],
            },
            // 搜索数据
            searchData: {
                name: '',
                sort: '',
                page: 1,
                size: 20
            },
            // 表格数据
            pageTotal: 0,
            tableloading: true,
            tableData: [],
            // 分类数据
            sortData: [],
            cronTime: {
                '0': '1分钟',
                '0.1': '6分钟',
                '0.2': '12分钟',
                '0.3': '18分钟',
                '0.4': '24分钟',
                '0.5': '半小时',
                '1': '1小时',
                '2': '2小时',
                '3': '3小时',
                '4': '4小时',
                '6': '6小时',
                '8': '8小时',
                '12': '12小时',
                '24': '24小时（中午十二点）',
            }
        }
    },
    created() {
        this.loadTable()
        this.loadSort()
    },
    methods: {
        // 表格请求
        loadTable() {
            let that = this
            this.ajax({
                url: '/hot/source',
                data: that.searchData,
                beforeSend() {
                    that.tableloading = true
                },
                complete() {
                    that.tableloading = false
                },
                success(res) {
                    that.pageTotal = res.data.count
                    that.tableData = res.data.list
                },
                error(res) {
                    that.$message.error(res.message);
                },
            })
        },
        // 分类请求
        loadSort() {
            let that = this
            that.ajax({
                url: '/hot/source_sort',
                success(res) {
                    that.sortData = res.data
                },
            })
        },
        // 搜索
        search() {
            this.searchData.page = 1
            this.loadTable()
        },
        // 翻页
        changePage(page) {
            this.searchData.page = page
            this.loadTable()
        },
        // 尝试爬取
        crawTest() {
            let that = this
            // 验证表单
            that.$refs['editForm'].validate(valid => {
                if (valid) {
                    that.crawTestOrigin = ''
                    that.crawTestRes = {}
                    that.ajax({
                        url: '/hot/craw_test',
                        type: 'post',
                        data: that.editData,
                        beforeSend() {
                            that.crawLoading = true
                        },
                        complete() {
                            that.crawLoading = false
                        },
                        success(res) {
                            that.crawTestOrigin = res.data.result
                            res.data = res.data.data
                            that.crawTestRes = res
                        },
                        error(res) {
                            if (res && res.status) {
                                that.crawTestOrigin = res.data.result
                                res.data = res.data.data
                                that.crawTestRes = res
                            } else {
                                that.crawTestOrigin = ''
                                that.crawTestRes = {
                                    status: 110000,
                                    message: '爬取失败，服务器错误'
                                }
                            }
                            delete res.data.result
                        },
                    })
                } else {
                    that.$message({
                        message: '请将表单填写完整',
                        type: 'warning'
                    });
                }
            });
        },
        // 打开弹窗
        popup(param, index) {
            // 编辑时记录index用于本地刷新表格
            if (index !== undefined) param.index = index
            this.crawTestRes = {}
            param.craw_type += ''
            param.craw_loop_type += ''
            this.crawLoading = false
            this.editLoading = false
            // 为了清空表单时不影响原始数据，执行深复制
            this.editData = JSON.parse(JSON.stringify(param))
            this.editShow = true
        },
        // 关闭弹窗
        closeEdit() {
            this.$refs['editForm'].resetFields()
        },
        // 删除平台
        deleteSource(index) {
            let that = this
            let data = that.tableData[index]
            that.$confirm('确认删除【' + data.name + '】？')
                .then(() => {
                    that.ajax({
                        url: '/hot/source',
                        type: 'delete',
                        data: {
                            id: data.id
                        },
                        beforeSend() {
                            that.tableloading = true
                        },
                        complete() {
                            that.tableloading = false
                        },
                        success() {
                            that.loadTable()
                            that.$message({
                                message: '删除成功',
                                type: 'success'
                            });
                        },
                        error(res) {
                            that.$message.error(res.message);
                        },
                    })
                })
                .catch(() => { })
        },
        // 点击提交
        submit() {
            let that = this
            // 验证表单
            that.$refs['editForm'].validate(valid => {
                if (valid) {
                    if (that.crawTestRes.status && that.crawTestRes.status == 100000) {
                        that.save()
                    } else {
                        that.$confirm('该配置未成功通过爬取测试，确认保存？')
                            .then(() => {
                                that.save()
                            })
                            .catch(() => { })
                    }
                } else {
                    this.$message({
                        message: '请将表单填写完整',
                        type: 'warning'
                    });
                }
            });
        },
        // 保存数据
        save() {
            let that = this
            let ajaxData = JSON.parse(JSON.stringify(that.editData))
            // delete ajaxData.id
            that.ajax({
                url: '/hot/source',
                type: 'post',
                data: ajaxData,
                beforeSend() {
                    that.editLoading = true
                },
                complete() {
                    that.editLoading = false
                },
                success() {
                    // 编辑本地刷新
                    if (ajaxData.id !== undefined) {
                        that.$set(that.tableData, ajaxData.index, ajaxData)
                    }
                    // 新增成功则请求刷新
                    else {
                        that.searchData.page = 1
                        that.loadTable()
                    }
                    that.editShow = false
                    that.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                },
                error(res) {
                    that.$message.error(res.message);
                },
            })
        },
        // 数据方法和循环方法改变
        dataTypeChange() {
            this.editData.craw_data = this.editData.craw_type == 1 ? '$("ul>li")' : 'JSON.parse(raw).data';
        },
        loopTypeChange() {
            if (this.editData.craw_loop_type == 1) {
                this.editData.craw_data_title = 'that.find(".title").text()';
                this.editData.craw_data_link = 'that.find("a").attr("href")';
            } else {
                this.editData.craw_data_title = 'hot[i].title';
                this.editData.craw_data_link = 'hot[i].url';
            }
        }
    }
}
</script>

<style lang="less" scoped>
@import url(//common.peal.cc/css/icon.css);

.pager {
    padding-top: 20px;
    text-align: right;
}

.iconfont {
    font-size: 34px;
    margin: 10px;
    display: inline-block;
}

.craw-detail>p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.el-input {
    font-size: 15px;
}

/deep/ input {
    font-family: monospace;
}
</style>